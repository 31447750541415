import React from 'react'
import Head from 'next/head'
import Link from 'next/link'
import dynamic from 'next/dynamic'

const Gift = dynamic(() => import('components/modules/base/Gift.jsx').then((mod) => mod.default), {
  ssr: false,
})

const PageIndex = () => {
  return (
    <div className="page page-index relative">
      <Head>
        <title>简约简历</title>
      </Head>
      <header className="verflow-hidden relative">
        <div className="relative z-2 mx-auto flex flex-col items-stretch justify-between xl:w-1200">
          <div className="mt-10 flex flex-col items-center px-4 md:mt-20 lg:px-0">
            <h1 className="text-base font-bold text-primary md:text-xl">简约简历</h1>
            <h2 className="mt-4 text-center text-xl font-medium text-gray-700 md:text-2xl lg:text-4xl">创建你的第一份在线简历，轻松分享，实时更新</h2>
            <div className="relative z-2 mt-6 flex flex-col rounded-xl bg-white p-2 md:mt-12 md:flex-row md:p-4 lg:-skew-x-6 lg:p-6">
              <div className="absolute top-6 -left-10 hidden h-14 w-14 rotate-45 rounded-full border-[16px] border-transparent border-t-white lg:block" />
              <div className="absolute top-6 -right-10 hidden h-14 w-14 rotate-45 rounded-full border-[16px] border-transparent border-l-white lg:block" />
              <Link href="/me/documents">
                <button className="button-primary h-12 w-40 rounded-xl text-sm font-bold md:mr-2 md:w-36">即刻开始</button>
              </Link>
              <Link href="/templates">
                <button className="button-normal mt-2 h-12 w-40 rounded-xl text-sm font-medium md:mt-0 md:ml-2 md:w-36">浏览简历模板</button>
              </Link>
            </div>
            <div className="relative -mt-14 flex w-full items-end justify-center pt-20 lg:w-900 lg:pt-28 xl:w-1200">
              <img src="/templates/resumes/1343/icon.svg" className="z-1 -mr-12 w-[30vw] rounded-t-xl md:w-56" alt="" />
              <img src="/templates/resumes/1342/icon.svg" className="z-2 w-[34vw] rounded-t-xl shadow-around-3xl shadow-black/7 md:w-64" alt="" />
              <img src="/templates/resumes/1352/icon.svg" className="z-1 -ml-12 w-[30vw] rounded-t-xl md:w-56" alt="" />
              <div className="absolute top-0 right-0 bottom-0 left-0 z-0 rounded-xl bg-gradient-to-br from-indigo-50 to-primary/10 lg:-skew-x-3" />
            </div>
          </div>
        </div>
      </header>
      <div className="mx-4 mt-12 rounded-3xl bg-gray-100 py-10 md:mt-24 md:py-24 lg:mx-auto lg:mt-36 lg:w-1000 lg:py-32 xl:w-1200">
        <div className="relative mx-auto flex flex-col items-center justify-between lg:w-900">
          <div className="z-2 flex flex-col items-center">
            <h4 className="text-gray-500 md:text-xl">告别传统简历</h4>
            <h3 className="mt-2 text-center text-xl font-bold text-gray-600 md:mt-4 md:text-2xl lg:text-4xl">你的能力，值得用更好的方式来展示</h3>
          </div>
          <div className="relarive z-2 mt-12 flex w-full flex-col items-center space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
            <div className="mx-4 flex flex-1 flex-col items-center justify-between rounded-xl bg-white py-12 md:mx-0">
              <img className="h-28" src="/icons/colored/icon_feat_online.svg" alt="真正的在线简历" />
              <div className="mx-8 mt-4 flex flex-1 flex-col items-center text-center">
                <h4 className="text-base font-medium text-gray-600">真正的在线简历</h4>
                <h5 className="mt-2 text-tiny text-gray-400">轻松分享连接给猎头或招聘方，并通过在线留言进行沟通</h5>
              </div>
            </div>
            <div className="mx-4 flex flex-1 flex-col items-center justify-between rounded-xl bg-white py-12 md:mx-0">
              <img className="h-28" src="/icons/colored/icon_feat_stat.svg" alt="查看访问统计" />
              <div className="mx-8 mt-4 flex flex-1 flex-col items-center text-center">
                <h4 className="text-base font-medium text-gray-600">查看访问统计</h4>
                <h5 className="mt-2 text-tiny text-gray-400">可以查看全部在线简历或者单个在线简历的近期访问情况</h5>
              </div>
            </div>
            <div className="mx-4 flex flex-1 flex-col items-center justify-between rounded-xl bg-white py-12 md:mx-0">
              <img className="h-28" src="/icons/colored/icon_feat_works.svg" alt="真正的在线简历" />
              <div className="mx-8 mt-4 flex flex-1 flex-col items-center text-center">
                <h4 className="text-base font-medium text-gray-600">关联你的作品</h4>
                <h5 className="mt-2 text-tiny text-gray-400">将你的天才作品关联到你的在线简历，充分展示你的才能</h5>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <Link href="/me/documents">
              <button className="button-primary h-14 w-64 rounded-full text-base font-bold">立即体验</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="mx-12 h-8 rounded-b-2xl bg-indigo-100 lg:mx-auto lg:w-800 xl:w-1000" />
      <div className="relative mt-12 flex flex-col items-stretch justify-between bg-gray-100 md:mt-24 lg:mt-32 lg:items-center">
        <div className="z-2 mt-12 flex flex-col items-center lg:mt-20">
          <h4 className="text-center text-xl font-medium text-gray-600">更多功能，等你探索</h4>
        </div>
        <div className="relarive z-2 mx-4 mt-8 mb-8 flex flex-col flex-wrap justify-center gap-4 rounded-2xl bg-gray-50 p-6 shadow-4xl shadow-black/5 md:mb-12 md:flex-row md:p-12 lg:mb-20 xl:mx-auto xl:w-1200">
          <div className="flex flex-1 flex-col items-center rounded-xl border border-gray-200 bg-white py-8">
            <img className="h-24" src="/icons/colored/icon_feat_clean.svg" alt="模板风格简约高效，自定颜色变化万千" />
            <h5 className="mx-12 mt-2 text-center text-sm text-gray-500">模板风格简约高效，自定颜色变化万千</h5>
          </div>
          <div className="flex flex-1 flex-col items-center rounded-xl border border-gray-200 bg-white py-8">
            <img className="h-24" src="/icons/colored/icon_feat_templates.svg" alt="简历模板持续更新，更多风格敬请期待" />
            <h5 className="mx-12 mt-2 text-center text-sm text-gray-500">简历模板持续更新，更多风格敬请期待</h5>
          </div>
          <div className="flex flex-1 flex-col items-center rounded-xl border border-gray-200 bg-white py-8">
            <img className="h-24" src="/icons/colored/icon_feat_pdf.svg" alt="支持下载高清PDF简历，线下投递不用愁" />
            <h5 className="mx-12 mt-2 text-center text-sm text-gray-500">支持下载高清PDF简历，线下投递不用愁</h5>
          </div>
          <div className="flex flex-1 flex-col items-center rounded-xl border border-gray-200 bg-white py-8">
            <img className="h-24" src="/icons/colored/icon_feat_lock.svg" alt="分享链接支持设置密码，保护你的隐私" />
            <h5 className="mx-12 mt-2 text-center text-sm text-gray-500">分享链接支持设置密码，保护你的隐私</h5>
          </div>
        </div>
      </div>
      <Gift />
    </div>
  )
}

PageIndex.options = {
  ssr: false,
  layoutClassName: 'bg-white',
  navBarFloat: true,
  navBarTransparent: true,
}

export default PageIndex
